import { Injectable, signal } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Environment } from '../../environments/environment';
import { IRestResponse } from '../core/interface/response.interface';
import { ICreateSenderPayload, IIsWabaVerifiedResp } from '../interfaces/user.interface';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userWABADetail = signal<IIsWabaVerifiedResp | null>(null);

  constructor(private restService: RestService) { }

  // public isWabaVerified(): Observable<boolean> {
  //   return this.restService.get(Environment.UrlPath + `/company/user/WABA`, null, true)
  //     .pipe(
  //       map((res: any) => {
  //         if (res && res.response) {
  //           return true
  //         } else {
  //           this.utilityServices.onNavigate('/unverified-waba')
  //           return false
  //         }
  //       }),
  //       catchError((error) => throwError(error)),
  //     )
  // }

  public isWabaVerified(companyId: string): Observable<IRestResponse<IIsWabaVerifiedResp>> {
    return this.restService.get(Environment.UrlPath + `/company/${companyId}/status`, null, true)
      .pipe(
        map(
          (res: any) => res),
        catchError((error) => throwError(error))
      )
  }

  public createSender(payload: ICreateSenderPayload): Observable<IRestResponse<any>> {
    return this.restService
      .post(Environment.UrlPath + `/twilio/sender`, payload, false, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  get getUserWabaDetail(): IIsWabaVerifiedResp | null {
    return this.userWABADetail()
  }

  set setUserWabaDetail(data: IIsWabaVerifiedResp) {
    if (data) {
      this.userWABADetail.set(data)
    }
  }

}
